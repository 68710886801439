//Form
import React from 'react';
import { useEffect } from "react";

import axios from 'axios';
import ConfirmationDialog from '../layout/confirmationDialog.js';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import PolicyTable from "./policyTable.js";
import {useFormContext} from "../customHook/useFormContext";
import VoucherTable from "./voucherTable.js";
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Form(props) {

{/*Cancel button event*/}
    const refreshPage = () => {
        window.location.reload();
    }

    const [
            values,
            setState,
            handleChange,
            ] = useFormContext();

    let previousClientID = 0;
    let API_URL = "";

    const host = window && window.location && window.location.hostname;

    if(host === "localhost"){
         API_URL = "https://localhost:6770/voucherpayout";
    }else if(host === "develop.deqlhlgpk8kw9.amplifyapp.com" || host === "www.voucherpayout-dev.metgetup.co.za"){
          API_URL = "https://api-dev.getup.metropolitan.co.za/voucherpayout";
    }else if(host === "www.voucherpayout.metgetup.co.za/"){
          API_URL = "https://api.getup.metropolitan.co.za/voucherpayout";
    }else if(host === "master.deqlhlgpk8kw9.amplifyapp.com"){
        API_URL = "https://api.getup.metropolitan.co.za/voucherpayout";
    }
    else
    {
          API_URL = "https://api-dev.getup.metropolitan.co.za/voucherpayout";
    }

    const post = async () => {
        let breakdown = {
            zendeskTicketNum : values.zendeskTicketNum,
            metQuoteRef : values.metQuoteRef,
            userName : values.userName,
            policies : values.policies,
            vouchers : values.vouchers
        }

        console.log(breakdown);
        axios({
          method: 'POST',
          url: API_URL + '/updateDatabase',
          data: breakdown,
          headers: {
          'Content-Type': 'application/json'
        }
        }).then(res => {
              console.log(res);
              alert('Successfully updated database');
           })
           .catch((error) => {
                if (error.response) {
                    // client received an error response (5xx, 4xx)
                    console.log(error.response.data.message);
                    alert(error.response.data.message);
                } else if (error.request) {
                    // client never received a response, or request never left
                    console.log(error.request);
                    alert(error.request);
                } else {
                    // anything else
                    console.log(error.message);
                    alert(error.message);
              }
           }
        );
    }

    const getLookups = async () => {
       const axios = require('axios');
       const res = await axios.post(API_URL + '/retrieveLookups');
       setState({
            ...values,
            'institutions' : res.data.externalInstitutions,
            'providers' : res.data.voucherProviders,
       });
    }

    useEffect(() => {
        getLookups();
    },[]);

    {/*Confirmation dialog */}
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');

     const handleClose = (media) => {
        setOpen(!open);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
         descriptionElement.focus();
        }
      }
    }, [open]);

      return (
        <div className="main-container">
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                    scroll="paper" maxWidth='sm' fullWidth={true}>
                <ConfirmationDialog handleClose={handleClose} ref={descriptionElementRef} post={post}/>
            </Dialog>
            <div className="form-container">
                <div className="textFieldGroup">
                    <div className="subContainer">
                        <label className="formLabels">Username: </label>
                        <TextField id="userName" name="userName" select value={values.userName} onChange={handleChange}
                                   size="small" style={{background: 'white', width: '40%', marginRight: '10px', textAlign: 'center'}}>
                            {values.allUsernames.map((option) => (
                                 <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                 </MenuItem>
                               ))}
                         </TextField>
                    </div>
                    <div className="subContainer">
                        <label className="formLabels">Zendesk Ticket Number: </label>
                         <TextField
                           id="zendeskTicketNum"
                           value={values.zendeskTicketNum}
                           onChange={handleChange}
                           variant="outlined"
                           size='small'
                           style={{background: 'white', width: '40%', height: '30px', marginRight: '10px'}}
                           inputProps={{  style: { height: '30px', padding: '0 14px' }  }}
                         />
                    </div>

                    <div className="subContainer">
                        <label className="formLabels">MetQuote Reference Number: </label>
                         <TextField
                           id="metQuoteRef"
                           value={values.metQuoteRef}
                           onChange={handleChange}
                           variant="outlined"
                           size='small'
                           style={{background: 'white', width: '40%', height: '30px', marginRight: '10px'}}
                           inputProps={{  style: { height: '30px', padding: '0 14px' }  }}
                         />
                    </div>
            </div>
                <div id="Company-policy-container">
                    <PolicyTable />
                </div>

                <div id="voucher-info-container">
                    <VoucherTable />
                </div>

                <div id="submitContainer">
                    {/*Add a function to clear fields and reset state*/}
                    <input type="reset" value="Clear" className="Buttons" onClick={refreshPage}/>
                    <input type="submit" value="Submit" className="Buttons" onClick={handleClose} />
                </div>
            </div>
        </div>
        );
}
export default Form