import { useContext } from "react";
import { Context } from "../stateContext/StateContext";

export const useFormContext = () => {

    const [values, setState] = useContext(Context); // Our values from Context

    let INITIAL_STATE = values;

    const handlePolicies = policies => {
        setState({
            ...values,
            "policies" : policies
        });
    }

    const handleVouchers = vouchers => {
        setState({
            ...values,
            "vouchers" : vouchers
        });
    }

    const handleChange = event => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.id ? target.id : target.name;
        setState({
            ...values,
            [name]: value
        });
    };

    return [
        values,
        setState,
        handleChange,
        handlePolicies,
        handleVouchers
    ];
}