//Navbar

import React, { Component } from 'react';

export default class Navbar extends Component{

    render() {
        return (
        <div className="header">
            <h1>Takealot Vouchers</h1>
        </div>
        );
    }
}