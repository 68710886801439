import './App.css';
import Navbar from './components/layout/navbar.js'
import Form from './components/voucher/form.js'
import { StateContext } from './components/stateContext/StateContext'

function App() {
  return (
    <div className="App">
    <StateContext>
        <Navbar/>
        <Form />
    </StateContext>
    </div>
  );
}

export default App;
