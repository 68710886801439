import React, {useState} from "react";

const Context = React.createContext([{}, () => {
}]); // Our empty Context ready.

const StateContext = props => {

     const INITIAL_STATE = {
         zendeskTicketNum: "",
         metQuoteRef: "",
         userName: "Akhona",
         voucherMax: 300,
         institutions: [],
         providers: [],

         policies: [{id: 1,
                    institutionName: "1-Life",
                    isEditMode: true,
                    policyNumber: "Policy1",
                    isEditMode: false}],

         vouchers:[
                    {id: 1,
                    isEditMode: true,
                    vendorName: "Takealot",
                    voucherAmount: 1000,
                    voucherNumber: "Voucher1",
                    isEditMode: false},
                    ],

         allUsernames: [
                     {
                         value:'Akhona',
                         label:'Akhona',
                     },
                     {
                         value:'Lyndall',
                         label:'Lyndall',
                     },
                     {
                         value:'Ryall',
                         label:'Ryall',
                     },
                     {
                         value:'Basi',
                         label:'Basi',
                     },
                 ],
     };

    const [values, setState] = useState(INITIAL_STATE); // Our context is a state hook ;)
         return <Context.Provider value={[values, setState]}>{props.children}</Context.Provider>;

};
export {Context, StateContext}