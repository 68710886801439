import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import {useFormContext} from "../customHook/useFormContext";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";

const useStyles = makeStyles(theme => ({
root: {
    width: "98%",
    maxHeight: 180,
    marginTop: theme.spacing(1),
    overflowX: "auto",
    overflowY: "auto",
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  }
}));


const handleFocus = (event) => event.target.select();

const VendorNameCell = ({row, onChange}) => {
    const classes = useStyles()
    const {isEditMode} = row;
    const [ values ] = useFormContext();

    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
             <TextField select value={row["vendorName"]} name="vendorName" onChange={e => onChange(e, row)} className={classes.input} >
                  {values.providers.map((option) => (
                       <MenuItem key={option.name} value={option.name}>
                          {option.name}
                       </MenuItem>
                           ))}
             </TextField>
            ) : (
                row["vendorName"]
            )}
        </TableCell>
    );
}

const VoucherAmountsCell = ({row, onChange}) => {
    const classes = useStyles();

    return (
        <TableCell align="left" className={classes.tableCell}>
                {row["voucherAmount"]}
        </TableCell>
    );
}


const VoucherNumberCell = ({row, onChange}) => {
    const classes = useStyles();
    const {isEditMode} = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
             <TextField
               value={row["voucherNumber"]}
               name="voucherNumber"
               onChange={e => onChange(e, row)}
               onFocus={handleFocus}
               className={classes.input}
               error={row["voucherNumber"]=== ""}
               helperText={row["voucherNumber"]=== "" ? 'Please enter a value!' : ' '}
             />
            ) : (
                row["voucherNumber"]
            )}
        </TableCell>
    );
}

function VoucherTable(props) {
    {/*Retrieve data from state context*/}
    const [
            values,
            setState,
            handleChange,
            handlePolicies,
            handleVouchers
            ] = useFormContext();

    const classes = useStyles();

    const [mirrorState, setMirrorState] = React.useState({
        'Vouchers' : values.vouchers,
    });

/*Add functionality to check that max total isn't exceeded*/
    const onToggleEditMode = (id) => {
          const newVouchers = values.vouchers.map(row => {
            if (row.id === id) {
              return { ...row,
                  isEditMode: !row.isEditMode,
              };
            }
            return row;
          });

          setState({...values,
                'vouchers' : newVouchers,
          });
          setMirrorState({
                'Vouchers' : newVouchers,
         });
    };

    const onChange = (e, row) => {
        const value = e.target.value;
        const name = e.target.name;
        const { id } = row;
        const newVouchers = values.vouchers.map(row => {
              if (row.id === id) {
                return { ...row, [name]: value };
              }
              return row;
            });
        setState({...values,
                'vouchers' : newVouchers,
        });
    };

    const onRevert = (id) => {
        const newVouchers = values.vouchers.map(row => {
            if (row.id === id){
                return mirrorState.Vouchers[id-1];
            };
            return row;
        });

        setState({...values,
                'vouchers' : newVouchers,
        });
    }

    return(
        <div>
            <Paper className={classes.root}>
                <Table className={classes.table} aria-label="caption table" id="VoucherTable">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" />
                        <TableCell align="left"><b>Vendor Name</b></TableCell>
                        <TableCell align="left"><b>Voucher Amount</b></TableCell>
                        <TableCell align="left"><b>Voucher Number</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.vouchers.map(row => (
                          <TableRow key={row.id}>
                            <TableCell className={classes.selectTableCell}>
                                {row.isEditMode ? (
                          <>
                            <IconButton
                              aria-label="done"
                              onClick={() => onToggleEditMode(row.id)}
                            >
                              <DoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="revert"
                              onClick={() => onRevert(row.id)}
                            >
                              <RevertIcon />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            aria-label="delete"
                            onClick={() => onToggleEditMode(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                            </TableCell>
                            <VendorNameCell{...{ row, onChange }} />
                            <VoucherAmountsCell{...{ row, onChange }} />
                            <VoucherNumberCell{...{ row, onChange }} />
                          </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}

export default VoucherTable