//policyTable
import React from "react";

import IconButton from "@material-ui/core/IconButton";
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useFormContext } from "../customHook/useFormContext";
// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import EditIcon from "@material-ui/icons/EditOutlined";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";

const useStyles = makeStyles(theme => ({
  root: {
    width: "98%",
    maxHeight: 180,
    marginTop: theme.spacing(1),
    overflowX: "auto",
    overflowY: "auto",
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 30
  },
  tableCell: {
    width: 120,
    height: 30
  },
  input: {
    width: 150,
    height: 30
  }
}));

const handleFocus = (event) => event.target.select();

{/*Creating row objects*/}
const createData = (id, policyNumber, institutionName) => ({
    id,
    policyNumber,
    institutionName,
    isEditMode: false
});

const PolicyNumberCell = ({row, onChange}) => {
    const classes = useStyles();
    const {isEditMode} = row;

    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
             <TextField
             value={row["policyNumber"]}
             name="policyNumber"
             onChange={e => onChange(e, row)}
             onFocus={handleFocus}
             className={classes.input}
             error={row["policyNumber"]=== ""}
             helperText={row["policyNumber"]=== "" ? 'Please enter a value!' : ' '} />
            ) : (
                row["policyNumber"]
            )}
        </TableCell>
    );
}

const InstitutionNameCell = ({row, onChange}) => {
    const classes = useStyles();
    const {isEditMode} = row;

    const [     values,
                handlePolicies,
                handleVouchers
                ] = useFormContext();

    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
             <TextField
             select
             value={row["institutionName"]}
             name="institutionName"
             onChange={e => onChange(e, row)} className={classes.input}>

                  {values.institutions.map((option) => (
                       <MenuItem key={option.name} value={option.name}>
                          {option.name}
                       </MenuItem>
                           ))}
             </TextField>
            ) : (
                row["institutionName"]
            )}
        </TableCell>
    );
}

let lastAddedID = 1;

function PolicyTable(props) {

    const [     values,
                setState,
                resetState,
                handleChange,
                handlePolicies,
                handleVouchers
                ] = useFormContext();

    const classes = useStyles();

    const [mirrorState, setMirrorState] = React.useState({
            'Policies' : values.policies,
        });

    const onToggleEditMode = id => {
        const newPolicies = values.policies.map(row => {
            if (row.id === id) {
                return { ...row,
                isEditMode: !row.isEditMode,
                };
              }
              return row;
          });

          setState({...values,
            'policies' : newPolicies,
          });
          setMirrorState({
             'Policies' : newPolicies,
          });
    };

    const onChange = (e, row) => {
      const value = e.target.value;
      const name = e.target.name;
      const { id } = row;
      const newPolicies = values.policies.map(row => {
        if (row.id === id) {
          return { ...row, [name]: value };
        }
        return row;
      });
      setState({...values,
        'policies' : newPolicies,
      });
    };

    const onRevert = (id) => {
        const newPolicies = values.policies.map(row => {
            if (row.id === id){
                return mirrorState.Policies[id-1];
            };
            return row;
        });

        setState({...values,
                'policies' : newPolicies,
        });
    };

    function handlePlusClick(e) {
      e.preventDefault();
      lastAddedID += 1;

      const data = values.policies.concat(createData(lastAddedID, "Policy" + lastAddedID,"1-Life"));

      setState({...values,
        'policies' : data
      });
      setMirrorState({
        'Policies' : data,
      });
    }

    function handleMinusClick(e) {
      e.preventDefault();
      const data = (values.policies.filter((item) => item.id !== lastAddedID));
      setState({...values,
        'policies' : data
      });
      setMirrorState({
         'Policies' : data,
      });
      lastAddedID-=1;
    }
  return(
  <div>
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table" id="PolicyTable">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="left"><b>Policy Number</b></TableCell>
              <TableCell align="left"><b>Institution Name</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.policies.map(row => (
              <TableRow key={row.id}>
                <TableCell className={classes.selectTableCell}>
                  {row.isEditMode ? (
                    <>
                      <IconButton
                        aria-label="done"
                        onClick={() => onToggleEditMode(row.id)}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        aria-label="revert"
                        onClick={() => onRevert(row.id)}
                      >
                        <RevertIcon />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      onClick={() => onToggleEditMode(row.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <PolicyNumberCell{...{ row, onChange }} />
                <InstitutionNameCell{...{ row, onChange }} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div style={{textAlign:'right', paddingRight: '10px'}}>
          <IconButton color="primary" aria-label="Add row to table" component="span" onClick={handlePlusClick}>
                <AddCircleIcon />
          </IconButton>
          <IconButton color="primary" aria-label="Add row to table" component="span" onClick={handleMinusClick}>
                <RemoveCircleIcon />
          </IconButton>
      </div>
  </div>
      );
}

export default PolicyTable
