import React from "react";

import Button from '@material-ui/core/Button';
import {useFormContext} from "../customHook/useFormContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        background: '#262626',
        color: '#D9D9D9',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '120px',
        padding: '10px',
    },
    header: {
        margin: 0,
    },
    buttonContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexWrap: 'no-wrap',
        flexBasis: '100%',
        height: '40px',
        justifyContent: 'center',
        width: '100px',
    },
    button: {
        height: '40px',
    },
}));

function ConfirmationDialog(props) {
    const classes = useStyles();
    const [ values ] = useFormContext();
    const submit = () => {
        props.post();
        props.handleClose();
    }

    return(

        <div className={classes.mainContainer}>
            <h3 className={classes.header}>Are you sure you would like to submit this form?</h3>
            <div className={classes.buttonContainer}>
                <Button variant="outlined" size="medium" color="primary" className={classes.button} style={{marginRight: '30px'}} onClick={submit}>
                  Yes
                </Button>
                <Button variant="outlined" size="medium" color="primary" className={classes.button} onClick={props.handleClose}>
                  No
                </Button>
            </div>
        </div>


    );
}
export default ConfirmationDialog